import { Shape } from "./DrawerTypes";
import { findRectSide } from "./DrawerUtils";

export interface RuleResultType {
  pass: boolean;
  message: string;
}

function resultType(pass: boolean, message: string): RuleResultType {
  return {
    pass: pass,
    message: message,
  };
}

function RuleCheckRectWithPointCount(
  shapes: Shape[],
  amount?: number
): RuleResultType {
  const classAmount = amount || 2;
  const rects = shapes.filter((shape) => shape.type === "rect");
  if (rects.length === 0) {
    //檢查是否有 rect
    return resultType(false, "Need to label at least one rect type. ");
  }
  const points = shapes.filter((shape) => shape.type === "point");
  if (points.length <= 1) {
    //檢查是否有 point
    return resultType(
      false,
      `Need to label at least ${classAmount} point type. `
    );
  }

  for (const rect of rects) {
    const { left, right, top, bottom } = findRectSide(rect.anchors);
    let checkPoint = [] as Shape[];
    points.forEach((point) => {
      const { x, y } = point.anchors[0];
      if (x < right && y < bottom && x > left && y > top) {
        checkPoint.push(point);
      }
    });
    if (checkPoint.length < classAmount) {
      return resultType(false, `Rect need to have ${classAmount} points.`);
    }
    if (checkPoint.length > classAmount) {
      return resultType(false, `Rect can only have ${classAmount} points.`);
    }
    if (
      checkPoint.length === classAmount &&
      checkPoint[0].classificationId === checkPoint[1].classificationId
    ) {
      return resultType(false, "Classification of point can't be the same.");
    }
  }
  return resultType(true, "Perfect !");
}

function RuleCheckPointInRect(shapes: Shape[]): RuleResultType {
  //const r = 10; //point半徑
  const rects = shapes.filter((shape) => shape.type === "rect");
  const points = shapes.filter((shape) => shape.type === "point");
  if (rects.length === 0) {
    return resultType(false, "Need to label at least one rect type.");
  }
  if (points.length === 0) {
    return resultType(false, "Need to label at least two point type. ");
  }
  for (const point of points) {
    const { x, y } = point.anchors[0];
    rects.forEach((rect) => {
      const { left, right, top, bottom } = findRectSide(rect.anchors);
      if (x < left || x > right || y < top || y > bottom) {
        return resultType(false, "Point need to be in rect.");
      }
    });
  }

  return resultType(true, "Perfect !");
}

function RuleCheckPointClass(shapes: Shape[]): RuleResultType {
  const points = shapes.filter((shape) => shape.type === "point");
  for (const point of points) {
    if (!point.classificationId) {
      return resultType(false, "Every points should have classification.");
    }
  }
  return resultType(true, "Perfect !");
}

function RuleCheckAllPointOnBinding(shapes: Shape[]): RuleResultType {
  const points = shapes.filter((shape) => shape.type === "point");
  const checkAll = points.every((shape) => shape.binding);
  return checkAll
    ? resultType(true, "Perfect !")
    : resultType(false, "Every points need to be in rect.");
}

// * check rule, 規定格式:{ pass:boolean,message:string }通過回pass
export const checkedLabelRules = {
  checkRectWithPointCount: RuleCheckRectWithPointCount,
  checkPointInRect: RuleCheckPointInRect,
  checkPointClass: RuleCheckPointClass,
  checkAllPointInRect: RuleCheckAllPointOnBinding,
};
