import styled from "@emotion/styled";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Space,
  Table, Tag,
  Typography,
  Upload
} from 'antd';
import axios from "axios";
import IframeResizer from 'iframe-resizer-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCanvas } from "../../contexts/DrawerContext";
import { LabelDataType } from "../../contexts/DrawerTypes";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { offlineUrlExpress } from "../../urls";

export interface ModelsContainerProps { }

const { Title, Text, Link } = Typography;

const MyTag = styled(Tag)`
  margin-bottom: 2px;
  :hover {
    color: darkblue;
  }
`;

const GroupDiv = styled.div`
  margin-right: 2rem;
  margin-left: 2rem;
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 0.6rem;
  }  
}
`;

const InnerDiv = styled.div``;

const ModelsContainer: React.SFC<ModelsContainerProps> = () => {


  const { currentUser } = useTypedSelector((state) => state.auth);
  const { setCanvasDrawOpen } = useCanvas();
  const [loading, setLoading] = useState(false);
  const [hasData, sethasData] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [filterDataset, setFilterDataset] = useState(dataset);
  const [getFilterOption, setFilterOption] = useState("User");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isParamsModalVisible, setIsParamsModalVisible] = useState(false);
  const [isUploadModelModalVisible, setIsUploadModelModalVisible] = useState(false);

  const [isEditModelNameVisible, setIsEditModelNameVisible] = useState(false);

  const [getSelectRecord, setSelectRecord] = useState({});
  const [getParams, setParams] = useState('');

  const [getResultUrl, setResultUrl] = useState("");

  const [isExportting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [progress, setProgress] = useState(0);

  const [searchText, setSearchText] = useState('');

  const upload_Props = {
    name: 'file',
    action: `${offlineUrlExpress}/api/importmodel/`,
    /*
    headers: {
      authorization: 'authorization-text',
    },
    */
    data: { username: currentUser },
    accept: 'zip,application/zip,application/x-zip,application/x-zip-compressed',
    showUploadList: false,
    onChange(info: any) {
      if (info.file.status === 'uploading') {
        setProgress(parseInt(info.file.percent));
        //message.info(`Uploading ${parseInt(info.file.percent)}%`,1);
      }
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setProgress(0);
      }
      if (info.file.status === 'done') {
        if (info.file.response.result === false) {
          message.error(`${info.file.name} file import failed, ${info.file.response.message}`, 5);
        } else {
          message.success(`${info.file.name} file import successfully`, 5);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`, 5);
      }
    }
  };

  const {
    datasetImageNavigateTo,
    setOnGoingLabelDataset,
    setOnGoingLabelDatasetNavigateTo,
  } = useActions();

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const columns = [
    /*
    {
      title: 'Model ID',
      dataIndex: 'modelid',
    },
    {
      title: 'Parent Model ID',
      dataIndex: 'parentmodelid'
    },
    */
    {
      title: 'Model Name',
      dataIndex: 'modelname',
      sorter: (a: any, b: any) => {
        a = a.modelname || '';
        b = b.modelname || '';
        return a.localeCompare(b);
      }
      /*
      render: (modelname: String) => {
        //console.log(modelname);
        return (
          <>
          <Text editable={{
            //onChange: setCustomIconStr,
          }}>{modelname}</Text>
          </>
        );      
      }
      */
    },
    {
      title: 'Score',
      dataIndex: 'score',
      render: (score: number) => {
        let tagcolor = "";
        if (score >= 0.5) {
          tagcolor = "green";
        } else if (score > 0.3 && score < 0.5) {
          tagcolor = "gold";
        } else {
          tagcolor = "red";
        }

        //let sclasses = Object.keys(classes).join(',')
        return (
          <Tag color={tagcolor}>{score.toFixed(2)}</Tag>
        );
      },
      sorter: (a: any, b: any) => a.score - b.score,
      /*  
      sorter: (a: any, b: any) => {
        a = a.score || '';
        b = b.score || '';
        return a.localeCompare(b);
      }
      */
    },
    {
      title: 'Type',
      dataIndex: 'modeltype',
      sorter: (a: any, b: any) => {
        a = a.modeltype || '';
        b = b.modeltype || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Classes',
      dataIndex: 'classes',
      render: (classes: string) => {
        let sclasses = Object.keys(classes).join(',')
        return (
          <div>{sclasses}</div>
        );
      },
      sorter: (a: any, b: any) => {
        a = Object.keys(a.classes).join(',') || '';
        b = Object.keys(b.classes).join(',') || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Dataset Name',
      dataIndex: 'datasetname',
      sorter: (a: any, b: any) => {
        a = a.datasetname || '';
        b = b.datasetname || '';
        return a.localeCompare(b);
      },
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a>{record.datasetname}</a>
          </Space>
        )
      },
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: async (ev: any) => {
            //console.log(record, rowIndex);

            const dataset = await axios.get(
              `${offlineUrlExpress}/api/dataset/${record.datasetid}`
            );

            if (dataset.data.length > 0) {
              const type = dataset.data[0].type
              if (type === 'KEYPOINT' || type === 'SEGMENTATION') {

                setCanvasDrawOpen(
                  dataset.data[0].id,
                  type as LabelDataType,
                  dataset.data[0].name,
                  dataset.data[0].classifications,
                  dataset.data[0].imagesCount,
                  0);
              } else {
                setOnGoingLabelDatasetNavigateTo(dataset.data[0].id, dataset.data[0].type, dataset.data[0].name, dataset.data[0].classifications, 0);
              }

            }

            //setOnGoingLabelDatasetNavigateTo(record.datasetid, record.type, record.datasetname, [], 0);
          },
        };
      },
    },

    /*
    classes
: 
{a: 6, B: 7}
create_at
: 
"2023-05-17 14:03:12"
datasetname
: 
"Hubrain_L1_V3"
lastupdate
: 
"2023-05-17 14:10:23"
modelid
: 
"110090ae-544f-47cd-8d61-027c39d71112"
modelname
: 
"TEMP"
modeltype
: 
"DETECT_AND_CLASSIFY"
params
: 
{epochs: 50, model_backbone: 'resnet50', learning_rate: 0.0001, optimizer: 'adam', use_pretrained: 'true', …}
parentmodelid
: 
null
score
: 
1
username
: 
"user001"
    */
    {
      title: 'Create By',
      dataIndex: 'username',
      sorter: (a: any, b: any) => {
        a = a.username || '';
        b = b.username || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Create Time',
      dataIndex: 'create_at',
      render: (create_at: string) => {
        let sdate = moment(create_at).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
      sorter: (a: any, b: any) => {
        a = a.create_at || '';
        b = b.create_at || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Last Update Time',
      dataIndex: 'lastupdate',
      render: (lastupdate: string) => {
        let sdate = moment(lastupdate).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
      sorter: (a: any, b: any) => {
        a = a.lastupdate || '';
        b = b.lastupdate || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Operation',
      width: 150,
      dataIndex: '',
      key: 'x',
      render: (record: any) => (
        <Space size="middle">
          {/*<MyTag color="cyan" onClick={(e) => { handleUploadModel(record); }} >Upload Model</MyTag>*/}
          <MyTag color="green" onClick={(e) => { handleEditModelName(record); }} >Rename</MyTag>
          <MyTag color="gold" onClick={(e) => { handleParams(record); }} >Params</MyTag>
          <MyTag color="geekblue" onClick={(e) => { handleDetail(record); }} >Detail</MyTag>
          <Popconfirm title="Sure to export?"
            onConfirm={() => handleExport(record)}
            okButtonProps={{ loading: isExportting }}
          >
            <MyTag color="green" >Export</MyTag>
          </Popconfirm>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
            <MyTag color="magenta" >Delete</MyTag>
          </Popconfirm>
        </Space>
      ),
      /*
    render: () => (
        <Space size="middle">
        <a>Detail</a>
        <a>Delete</a>
        </Space>
    )
    */
    }
  ];

  const handleDelete = async (sdata: any) => {
    //const dataSource = [...dataset];
    console.log(sdata);

    let deleteModelId = sdata.modelid;

    axios.post(`${offlineUrlExpress}/api/deletemodel/` + deleteModelId)
      .then(res => {
        console.log(res);
        console.log(res.data);
        sethasData(false);
        fetchGetModelsList();
      })
    //setDataset(dataSource.filter(item => item.modelid !== modelid));
  };

  const handleDetail = (sdata: any) => {
    //const dataSource = [...dataset];
    console.log(sdata);
    showModal(sdata);
    //setDataset(dataSource.filter(item => item.modelid !== modelid));
  };

  const handleExport = async (sdata: any) => {
    //message.loading({ content: 'Model Export...', key, 0 });
    const hide = message.loading('Model Export...', 0);

    setIsExporting(true);
    axios({
      method: 'get',
      url: `${offlineUrlExpress}/api/exportmodel/${sdata.modelid}`,
      responseType: 'blob',
      headers: {},
    })
      .then((res) => {

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Model_' + sdata['modelname'] + ".zip");
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setTimeout(hide, 500);
        message.success(`${sdata.modelname} export successfully`);
      })
      .catch((error) => {
        alert(error);
        setIsExporting(false);
      })

    /*
    const { data } = await axios.get(
      `${offlineUrlExpress}/api/exportmodel/${sdata.modelid}`
    );
    */
  };

  const handleParams = (sdata: any) => {
    console.log(sdata);
    setSelectRecord(sdata);
    setParams(JSON.stringify(sdata.params, null, 4));
    setIsParamsModalVisible(true);
  };

  const handleUploadModel = (sdata: any) => {
    console.log(sdata);
    setSelectRecord(sdata);
    setIsUploadModelModalVisible(true);
  };


  const handleEditModelName = (sdata: any) => {
    setSelectRecord(sdata);
    formEditModelName.setFieldsValue({
      modelname: sdata['modelname'],
    })
    setIsEditModelNameVisible(true);
  };

  const fetchGetModelsList = async () => {
    //const jwt = localStorage.getItem("jwt");
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${offlineUrlExpress}/api/modelslist`
      );
      if (JSON.stringify(dataset) != JSON.stringify(data)) {
        //console.log(dataset)
        //console.log(data)

        setDataset(data);
        for (let i = 0; i < data.length; i++) {
          if (data[i].score == 0.01) {
            axios.get(`${offlineUrlExpress}/api/updatemodelscore/${data[i].modelid}`);
            console.log(data[i].modelid, data[i].score);
          }
        }

        console.log("Update ModelsList datatable !!!")

        let filterDataset;

        if (getFilterOption === 'All') {
          filterDataset = data;
        } else if (getFilterOption === 'Other') {
          filterDataset = data.filter(
            ({ username }: any) => username !== currentUser
          );
        } else if (getFilterOption === 'User') {
          filterDataset = data.filter(
            ({ username }: any) => username === currentUser
          );
        }
        setFilterDataset(filterDataset);

      }
      setLoading(false);
      sethasData(true);
      //console.log(dataset);
    } catch (err: any) {
      //message.error(err.message);
      setLoading(false);
      sethasData(true);
    }
  };

  const showModal = (sdata: any) => {
    console.log(sdata);
    let modelid = sdata.modelid;
    let fullmodelid = `${offlineUrlExpress}/logs/${modelid}/log.txt`;
    setResultUrl("ShowTrainStatus.html?taskidurl=" + fullmodelid);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsParamsModalVisible(false);

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsParamsModalVisible(false);
  };

  const [formUploadModel] = Form.useForm();

  const handleUploadModelOk = () => {
    formUploadModel
      .validateFields()
      .then(values => {
        let recordData: any = getSelectRecord;
        console.log(values);
        if (values['model_server'] == "PredictServer2") {
          message
            .loading('Upload Model in progress..', 2.5)
            .then(() => message.error('Model Upload Fail!'));
        } else {
          message
            .loading('Upload Model in progress..', 2.5)
            .then(() => message.success('Model Upload Success', 2.5))
        }
        /*
        axios.post(`${offlineUrlExpress}/api/testmodel/${id}`, JSON.stringify(postData), {headers: { 'Content-Type': 'application/json' }})
        .then(res => {
          history.push('/home/tasks')
        })
        */
        formUploadModel.resetFields();
        setIsUploadModelModalVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //history.push('/home/tasks')

  };

  const handleUploadModelCancel = () => {
    formUploadModel.resetFields();
    setIsUploadModelModalVisible(false);
  };

  const [formEditModelName] = Form.useForm();

  const handleEditModelNameModelNameOk = () => {
    formEditModelName
      .validateFields()
      .then(values => {
        let recordData: any = getSelectRecord;
        if (values['modelname'] !== recordData['modelname']) {
          console.log(recordData, values);
          let postData = { modelname: values['modelname'] }

          axios.put(`${offlineUrlExpress}/api/editmodelname/${recordData['modelid']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              message.success('Model Name Edit Success', 1)
              sethasData(false);
            })


        }
        /*
        axios.put(`${offlineUrlExpress}/api/testmodel/${id}`, JSON.stringify(postData), {headers: { 'Content-Type': 'application/json' }})
        .then(res => {
          history.push('/home/tasks')
        })
        */
        formEditModelName.resetFields();
        setIsEditModelNameVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //history.push('/home/tasks')

  };

  const handleEditModelNameModelNameCancel = () => {
    formEditModelName.resetFields();
    setIsEditModelNameVisible(false);
  };


  /*
        if (getFilterOption === 'All') {
          filterDataset = data;
        } else if (getFilterOption === 'Other') {
          filterDataset = data.filter(
            ({ userId }: any) => userId != 1
          );
        } else if (getFilterOption === 'User') {
          filterDataset = data.filter(
            ({ userId }: any) => userId == 1
          );
        }
        */

  const filterUser = () => {

    let filterDataset = dataset.filter(
      ({ username }: any) => username === currentUser
    );

    setFilterOption('User');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };

  const filterOther = () => {

    let filterDataset = dataset.filter(
      ({ username }: any) => username !== currentUser
    );

    setFilterOption('Other');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };

  const filterAll = () => {

    let filterDataset = dataset;

    setFilterOption('All');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };

  useEffect(() => {
    if (!hasData) {
      fetchGetModelsList();
    }

    const timer = window.setInterval(() => {
      fetchGetModelsList();
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };

  });

  return (
    <>
      <InnerContainer>
        <InnerDiv>
          <Title>Models List</Title>
        </InnerDiv>
        <GroupDiv>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterUser(); }}
            danger={getFilterOption === 'User'}
          >
            USER
          </Button>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterOther(); }}
            danger={getFilterOption === 'Other'}
          >
            OTHER
          </Button>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterAll(); }}
            danger={getFilterOption === 'All'}
          >
            ALL
          </Button>
        </GroupDiv>
      </InnerContainer>
      {progress > 0 &&
        (<><Text>Uploading :</Text>
          <Progress strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }} percent={progress} /></>)
      }

      <Row justify="end">

        <Space size="middle">
          {/* <MyTag color="geekblue" onClick={(e) => { handleAddTrain(); }}>Add Train Task</MyTag>
      <MyTag color="geekblue" onClick={(e) => { handleAddValid(); }}>Add Validate Task</MyTag> */}
          <MyTag color="gold" onClick={(e) => { fetchGetModelsList(); }}>Reload</MyTag>
          <Upload {...upload_Props}>
            <MyTag color="geekblue">Import</MyTag>
          </Upload>
          <Input.Search
            //style={{ marginLeft: 420 }}
            placeholder="keywords Search..."
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Space>
      </Row>
      <Table
        columns={columns}
        rowKey="modelid"
        //dataSource={filterDataset}       
        dataSource={filterDataset.filter(
          (item: any) =>
            (item.modelname || '').toLowerCase().includes(searchText.toLowerCase())
            || (item.datasetname || '').toLowerCase().includes(searchText.toLowerCase())
        )}
        pagination={{ pageSize: 10 }}
      //scroll={{ y: 600 }}
      />

      <Modal title="Detail Results" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <div>
          <IframeResizer
            log
            inPageLinks
            //forwardRef={ref}        
            //onMessage={onMessage}
            //onResized={onResized}        
            src={getResultUrl}
            width="100%"
            scrolling={true}
            height="700px"
            frameBorder="0"
          />
        </div>
      </Modal>
      <Modal title="Params" visible={isParamsModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <div>
          <pre>{JSON.stringify(getSelectRecord, null, 4)}</pre>
        </div>
      </Modal>

      <Modal
        title="Model Upload"
        visible={isUploadModelModalVisible}
        onOk={handleUploadModelOk}
        onCancel={handleUploadModelCancel}
        width={1000}
      >
        <Form form={formUploadModel}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ model_server: "" }}
        >
          <Form.Item label="Predict Model Server" name="model_server" rules={[{ required: true, message: 'Predict Model Server is Required' }]}>
            <Select>
              <Select.Option key="ps1" value="PredictServer1">Predict Server 1</Select.Option>
              <Select.Option key="ps2" value="PredictServer2">Predict Server 2</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Model Name"
        visible={isEditModelNameVisible}
        onOk={handleEditModelNameModelNameOk}
        onCancel={handleEditModelNameModelNameCancel}
        width={1000}
      >
        <Form form={formEditModelName}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ modelname: "" }}
        >
          <Form.Item label="Model Name" name="modelname" rules={[{ required: true, message: 'Model Name is Required' }]}>
            <Input placeholder="Model Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModelsContainer;
