
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import short from 'short-uuid';
import { CanvasOptionType } from '.';
import { useCanvas } from '../../contexts/DrawerContext';
import { AnchorType, Shape, ShapeType } from '../../contexts/DrawerTypes';
import EditorMiddleware from './EditorMiddleware';
import PainterMiddleware from './PainterMiddleware';
import ShapeEditMenu, { EmptyModal } from './ShapeEditMenu';

const initMenuProps = {
    show: false,
    x: 0,
    y: 0
};

const limitSize = {
    width: 1200,
    height: 660
}

const ImageCanvas = ({
    imageUrl,

    option,
    onSetCanvasOption
}: {
    imageUrl: string,

    option: CanvasOptionType,
    onSetCanvasOption: (key: string, value: string | boolean | number) => void
}) => {
    const { locked,
        onDraw,
        rectColor,
        defaultClassification: classification,
        shapeType: drawType,
        fill,
        brightness,

        radius } = option;
    const { canvasState, selectCurrentShape, updateCurrentDataset, setRecordHistory, } = useCanvas();
    const { selectShape, shapes, classifications, classSummary } = canvasState;
    const [menuProps, setMenuProps] = useState(initMenuProps)

    //const [imageOriginSize, setImageOriginSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });//轉相對座標使用
    const [container, setContainer] = useState<[number, number]>([0, 0]);
    const [canvasImage, setCanvasImage] = useState<HTMLImageElement | undefined>(undefined);
    const [newShape, setNewShape] = useState<Shape | null>(null);
    const [nextPoint, setNextPoint] = useState<{ x: number, y: number } | null>(null);
    const [emptyShape, setEmptyShape] = useState('');

    const imageRef = useRef<Konva.Image | null>(null);
    /**
    * * 轉相對座標 
    * @param anchors 
    * @returns 
    */
    const convertRelatively = (anchors: AnchorType[]) => {
        const [width, height] = container;
        return anchors.map(anchor => {
            return {
                ...anchor,
                x: anchor.x / width,
                y: anchor.y / height
            }
        });
    };

    /**
    * * 轉絕對座標 
    * @param anchors 
    * @returns 
    */
    const convertAbsolute = (anchors: AnchorType[]) => {
        const [width, height] = container;
        return anchors.map(anchor => {
            return {
                ...anchor,
                x: anchor.x * width,
                y: anchor.y * height
            }
        });
    };
    //setLoadingImage
    useLayoutEffect(() => {
        try {
            const img = new Image()
            img.onload = (e: Event) => {
                const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                //setImageOriginSize({ width: naturalWidth, height: naturalHeight });
                const rh = limitSize.height;;
                const rw = rh * (naturalWidth / naturalHeight);
                const w = rw > limitSize.width ? limitSize.width : rw;
                const h = rh * (w / rw);
                img.width = w;
                img.height = h;
                setContainer([img.width, img.height]);
                setCanvasImage(img);
            };
            img.src = imageUrl;
            img.crossOrigin = 'Anonymous';
            return () => {
                img.onload = null;
            };
        } catch (err) {
            console.log(err)
        }
    }, [imageUrl]);

    useEffect(() => {
        if (canvasImage && imageRef && imageRef.current) {
            imageRef.current.cache();
            imageRef.current.filters([Konva.Filters.Brighten]);
            imageRef.current.brightness(brightness);
            imageRef.current.getLayer()!.batchDraw();
        }
    }, [brightness, canvasImage]);

    /**
     * * 追蹤滑鼠座標不超過指定範圍
     * @param x 
     * @param y 
     * @returns 
     */
    const outRangeCheck = (x: number, y: number) => {
        if (x < 0 || x > canvasImage!.width || y < 0 || y > canvasImage!.height) {
            return false;
        }
        return true;
    };

    /**
    * * 創建下一個座標位置，以利追蹤
    * @param x 
    * @param y 
    */
    const createNextAnchorPoint = (x: number, y: number) => {
        if (newShape && drawType) {
            const { anchors } = newShape;
            const updatedPolygon = {
                ...newShape,
                anchors: [...anchors, { x: x, y: y, index: anchors.length.toString() }]
            };
            setNewShape(updatedPolygon);
        }
    };

    /**
     * * 創建矩形的第一個座標位置
     * @param x 
     * @param y 
     */
    const createFirstAnchorPoint = (x: number, y: number) => {
        const newShape = {
            id: 'new-drawing',
            type: drawType as ShapeType,
            anchors: [{ x: x, y: y, index: '0' }],
            isComplete: false,
            visible: true,
            classificationId: classification,
            binding: undefined
        };
        onSetCanvasOption('drawing', true);
        setNewShape(newShape);
    };

    /**
   * * 畫完矩形狀態傳至context
   */
    const handleShapeComplete = () => {
        if (newShape) {
            const { type, anchors, classificationId } = newShape;
            const completeShape = { ...newShape };
            completeShape['id'] = short.uuid();
            completeShape['isComplete'] = true
            if (type === 'polygon') {
                if (anchors.length <= 3) return
                anchors.pop();//拿掉最後一個項目
                completeShape['anchors'] = convertRelatively([...anchors]);
            }
            if ((type === 'rect' || type === 'circle') && nextPoint) {
                completeShape['anchors'] = convertRelatively([...anchors, { index: '1', x: nextPoint.x, y: nextPoint.y }]);
            }
            if (type === 'point') {
                completeShape['anchors'] = convertRelatively(anchors);
            }
            if (classificationId === null) {
                setEmptyShape(completeShape.id)
            }
            updateCurrentDataset([...shapes, completeShape]);
            setRecordHistory([...shapes, completeShape]);
            setNewShape(null);
            setNextPoint(null)
            onSetCanvasOption('drawing', false);
        }
    };

    /**
     * * 追蹤滑鼠點下
     * @param e 
     * @returns 
     */
    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (onDraw && e.evt.button === 0) {
            const { x, y } = e.target.getStage()!.getPointerPosition() as Vector2d;
            if (!outRangeCheck(x, y)) return;
            if (!newShape) {
                return createFirstAnchorPoint(x, y)
            };
            if (nextPoint) {
                if (drawType === 'polygon') {
                    return createNextAnchorPoint(x, y)
                };
                if (drawType === 'rect') {
                    return handleShapeComplete();
                };
                if (drawType === 'circle') {
                    if (nextPoint.x < 5 || nextPoint.y < 5) {
                        return;
                    }
                    return handleShapeComplete();
                };
            }
        };
        if (e.target.getAttrs().name === 'Image') {
            setMenuProps(initMenuProps);
            selectCurrentShape(null)
            return
        };
    };

    /**
     * * 追蹤滑鼠移動
     * @param e 
     * @returns 
     */
    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (!onDraw) return;
        if (newShape) {
            const { x, y } = e.target.getStage()!.getPointerPosition() as Vector2d;
            if (newShape.type === 'circle') {
                const { x: ox, y: oy } = newShape.anchors[0];
                const r = Math.abs(ox - x) > Math.abs(oy - y) ? Math.abs(ox - x) : Math.abs(oy - y);
                setNextPoint({ x: r, y: r });
            } else {
                setNextPoint({ x, y });
            }
        };
    };

    const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (!onDraw) return;
        if (newShape && newShape.type === 'point') {
            return handleShapeComplete();
        };
    };
    const handleStageContext = (evt: Konva.KonvaEventObject<PointerEvent>) => {
        evt.evt.preventDefault()
        if (onDraw) {
            setNextPoint(null)
            setNewShape(null);
        }
    };

    /**
     * * 指定當前矩形
     * @param rect 
     */
    const handleSelect = (shape: Shape) => {
        if (onDraw) return;
        setMenuProps(initMenuProps);
        selectCurrentShape(shape);
    };


    /**
   * * 更新整體矩形dataset
   * @param polygon 
   */
    const handleUpdatedDataset = (shape: Shape) => {
        const updatedShapeDataset = [...shapes];
        const index = updatedShapeDataset.findIndex(rect => rect.id === shape.id);
        updatedShapeDataset.splice(index, 1, shape);
        updateCurrentDataset(updatedShapeDataset);
    };

    /**
     * * 更新選擇中的矩形
     * @param anchors 
     */
    const handleUpdatedEditShape = (anchors: AnchorType[]) => {
        if (selectShape) {
            const updatedSelectShape = {
                ...selectShape,
                anchors: convertRelatively(anchors)
            };
            handleUpdatedDataset(updatedSelectShape)
            selectCurrentShape(updatedSelectShape);
        }
    };

    /**
    * * 按右鍵觸發menu context
    * @param e 
    */
    const handleContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        if (e.target.attrs.points) {
            const points: number[] = e.target.attrs.points;
            const pointX = points.filter((_, index) => index % 2 === 0);//取出所有x值
            const pointY = points.filter((_, index) => index % 2 !== 0);//取出所有y值
            const minX = Math.min(...pointX);
            const maxX = Math.max(...pointX);
            const minY = Math.min(...pointY);
            const maxY = Math.max(...pointY);
            const side = {
                left: minX,
                right: maxX,
                top: minY,
                bottom: maxY,
            };
            setMenuProps({
                show: true,
                y: (side.top + 100) > container[1] ? side.top - 50 : side.top,
                x: (side.right + 240) > container[0] ? side.right - 300 : side.right
            });
        } else {
            const { x, y } = e.target.attrs;
            setMenuProps({
                show: true,
                y: (y + 100) > container[1] ? y - 50 : y,
                x: (x + 240) > container[0] ? x - 300 : x
            });
        }
    };

    const shapeViewer = selectShape ? shapes.filter(shape => shape.id !== selectShape.id) : shapes;

    return (
        <div style={{ position: 'relative' }} onContextMenu={(e) => e.preventDefault()}>
            {selectShape &&
                <ShapeEditMenu shapeId={selectShape.id} x={menuProps.x} y={menuProps.y} show={menuProps.show}
                    onClose={() => setMenuProps(initMenuProps)}
                />}
            <EmptyModal shapeId={emptyShape} onClose={() => setEmptyShape('')} />
            <Stage
                width={canvasImage?.width}
                height={canvasImage?.height}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onContextMenu={handleStageContext}
            >
                <Layer>
                    <KonvaImage
                        image={canvasImage}
                        name='Image'
                        ref={imageRef} opacity={1}
                        filters={[Konva.Filters.Brighten]}
                    />
                    {shapeViewer.map(shape => {
                        const target = classifications.find(ele => ele.id === shape.classificationId);
                        const summary = classSummary.find(item => item.class === target?.cid);
                        return <PainterMiddleware
                            key={shape.id}
                            shape={shape}
                            points={convertAbsolute(shape.anchors)}
                            nextPoint={null}
                            rectColor={summary?.color || rectColor}
                            fill={fill}
                            radius={radius}
                            handleSelect={() => handleSelect(shape)}
                            handleCompleted={handleShapeComplete}
                            handleContext={() => setNewShape(null)}
                        />
                    })}
                    {selectShape &&
                        <EditorMiddleware
                            shape={selectShape}
                            anchors={convertAbsolute(selectShape.anchors)}
                            rectColor={'#b023e1'}
                            fill={fill}
                            radius={radius}
                            handleUpdateEditShape={handleUpdatedEditShape}
                            handleContext={handleContext}
                        />}
                    {newShape && drawType &&
                        <PainterMiddleware
                            shape={newShape}
                            points={newShape.anchors}
                            nextPoint={nextPoint}
                            rectColor={rectColor}
                            fill={fill}
                            radius={radius}
                            handleSelect={() => handleSelect(newShape)}
                            handleCompleted={handleShapeComplete}

                        />
                    }
                </Layer>
            </Stage>
        </div>
    )
}

export default ImageCanvas