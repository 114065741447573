import styled from "@emotion/styled";

import { PlusCircleOutlined } from "@ant-design/icons";
import { useActions } from "../../hooks/useActions";
import { Classification } from "../../states/reducers/datasets";
import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";
import DatasetTableClassification from "../Dataset-Table-Classification";

const MyIconContainer = styled.span`
  :hover {
    color: blue;
  }
`;

const ClassificationContainer = styled.div`
  max-width: 15rem;
`;

export interface DatasetTableClassificationsProps {
  classifications: Classification[];
  datasetId: number;
  datasetType: string;
}

const DatasetTableClassifications: React.FC<DatasetTableClassificationsProps> = ({
  classifications,
  datasetId,
  datasetType,
}) => {
  const { setClassificationEditOpen } = useActions();
  return (
    <ClassificationContainer>
      {classifications.map((classification, i) => {
        let color = i % 2 === 0 ? "geekblue" : "green";
        return (
          <DatasetTableClassification
            datasetId={datasetId}
            key={datasetId + "_ " + i}
            color={color}
            classification={classification}
          />
        );
      })}
      {(datasetType.includes("CLASSIFY") || datasetType.includes('SEGMENTATION')) && (
        <MyIconContainer
          onClick={() =>
            setClassificationEditOpen(
              TableClassificationEditTask.ADD,
              null,
              datasetId
            )
          }
        >
          <PlusCircleOutlined color="inherit" />
        </MyIconContainer>
      )}
    </ClassificationContainer>
  );
};

export default DatasetTableClassifications;
