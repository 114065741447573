import axios from "axios";
import { offlineUrlExpress } from "../urls";
import { LabelDataType, Shape, ShapeImageJson } from "./DrawerTypes";
import { formatBody } from "./DrawerUtils";

export async function fetchShapeDatasetApi(
  datasetId: number,
  currentIndex: number,
  labeled?: boolean,
  classed?: string,
  isImport?: boolean,
  source?: string,
  options?: { [key: string]: any }
) {
  return axios.get<ShapeImageJson[]>(
    `${offlineUrlExpress}/api/dataset-images/${datasetId}`,
    {
      params: {
        skip: currentIndex,
        take: 1,
        labeled: labeled,
        class: classed,
        isimport: isImport,
        source: source,
      },
    }
  );
}

export async function fetchClassSummaryApi(datasetId: number) {
  const url = `${offlineUrlExpress}/api/getdatasetclassessummary/${datasetId}`;
  return axios.get(url);
}

export async function saveDetectLabels(
  datasetType: LabelDataType,
  datasetImageId: number,
  shapes: Shape[]
) {
  const result = formatBody(datasetType, shapes);
  console.log("result", result);
  if (!result.formatting) {
    throw new Error(result.message);
  }
  try {
    await axios.post(
      `${offlineUrlExpress}/api/dataset-images/detect-labels/${datasetImageId}`,
      {
        coordinates: result.data,
      }
    );

    return {
      success: true,
      message: "save success",
      data: null,
    };
  } catch (err: any) {
    return {
      success: true,
      message: "save failed",
      data: null,
    };
  }
}

export async function deleteImageByIdAsync(imageId: number) {
  try {
    const { data } = await axios.delete(
      `${offlineUrlExpress}/api/dataset-images/${imageId}`
    );
    if (data) {
      return {
        success: true,
        message: "Image deleted",
        data: null,
      };
    } else {
      throw new Error("Delete failed");
    }
  } catch (err: any) {
    return {
      success: true,
      message: "Delete failed",
      data: null,
    };
  }
}
