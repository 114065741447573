
import { Button, Popover } from 'antd';
import { useCanvas } from '../../contexts/DrawerContext';
import { ClassSummary } from '../../contexts/DrawerTypes';
import ClassColorPicker from './ClassColorPicker';

const ClassPicker = ({
    classItem, onClass, onClick

}: {
    classItem: ClassSummary,
    onClass: boolean,
    onClick: () => void
}) => {

    const { canvasState, dispatchCanvas } = useCanvas();
    const { classSummary } = canvasState;

    const colors = classSummary.filter(item => item.color && item.color !== 'none').map(item => item.color!);

    const classColor = (classItem.color && classItem.color !== 'none') ? classItem.color : 'gray';

    const handlePickColor = (color: string | undefined) => {
        const newClassSummary = canvasState.classSummary.map(item => {
            if (item.class === classItem.class) {
                return {
                    ...item,
                    color: color
                }
            } else {
                return item
            }
        });
        dispatchCanvas({
            type: 'set_shape_classification_color',
            classSummary: newClassSummary
        })
    };

    return (
        <div
            style={{
                width: '90%', display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
            <Button
                danger={onClass}
                onClick={() => onClick()}
                style={{ width: 150, display: 'flex', justifyContent: 'space-between' }}
                size='small'
                disabled={classItem.count === '0'}
            >
                <section>
                    {classItem.classlabel}
                </section>
                <section>
                    {classItem.count} / {classItem.imagecount}
                </section>

            </Button>

            {classItem.class > 0 &&
                <Popover
                    content={<ClassColorPicker
                        currentColors={colors}
                        onPickColor={handlePickColor}
                    />} title="Class Color Picker" trigger="click"
                >
                    <Button size='small'>
                        <div style={{ width: 12, height: 12, borderRadius: 6, background: classColor }} />
                    </Button>
                </Popover>}

        </div>
    )
}

export default ClassPicker