
import { BorderOutlined, CaretLeftOutlined, CaretRightOutlined, LockOutlined, PlusCircleFilled, ShareAltOutlined, UnlockOutlined } from '@ant-design/icons';

import { Button, Modal, Select } from 'antd';
import React, { useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';


import { CanvasOptionType, DataOptionType } from '.';
import { useCanvas } from '../../contexts/DrawerContext';
import { ShapeType } from '../../contexts/DrawerTypes';
import { offlineUrlExpress } from '../../urls';
import LabelSyntheticModal from '../Label-Synthetic-Modal';
import ClassPicker from './ClassPicker';

const DeleteImageConfirm = () => {
    return new Promise((resolve) => {
        Modal.confirm({
            title: 'Delete image warning!',
            content: 'This operation cannot be undone, are you sure you want to continue?',
            okText: 'Sure',
            onOk: () => resolve(true),    // OK 返回 true
            onCancel: () => resolve(false) // Cancel 返回 false
        });
    });
}

const ChangePageConfirm = () => {
    return new Promise((resolve) => {
        Modal.confirm({
            title: 'Found new label !',
            content: 'You have new label that has not been saved. Are you sure you want to continue?',
            okText: 'Sure',
            onOk: () => resolve(true),    // OK 返回 true
            onCancel: () => resolve(false) // Cancel 返回 false
        });
    });
}

const shapeButton: { shape: ShapeType, icon: React.ReactNode }[] = [
    { shape: 'point', icon: <PlusCircleFilled /> },
    // { shape: 'line', icon: <LiaSquare /> },
    { shape: 'rect', icon: <BorderOutlined /> },
    // { shape: 'circle', icon: },
    { shape: 'polygon', icon: <ShareAltOutlined /> },
];


const Controller = ({
    canvasOption,
    dataOption,
    onSetCanvasOption,
    onSetDataOption,
    onDrawShape
}: {
    dataOption: DataOptionType
    canvasOption: CanvasOptionType,
    onSetCanvasOption: (key: string, value: string | boolean | number) => void// (option: CanvasOptionType) => void;
    onSetDataOption: (option: DataOptionType) => void;
    onDrawShape: (type: ShapeType) => void
}) => {

    const { canvasState, postSaveCanvasLabelAsync, deleteImageAsync, setNavigateTo, filterByClassification } = useCanvas();
    const { imagesCount, currentIndex, classSummary, shapes, classifications, error, allowTypes, currentImage, datasetId, modalOpen } = canvasState;

    const saveRef = useRef<HTMLButtonElement | null>(null);
    const preRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const lockRef = useRef<HTMLButtonElement | null>(null);
    const drawRef = useRef<HTMLButtonElement | null>(null);
    const removeRef = useRef<HTMLButtonElement | null>(null);

    const [modal, contextHolder] = Modal.useModal();

    const [confirm, setConfirm] = useState(false);

    const handleGuideToIndex = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (canvasOption.locked && confirm) return;
        const index = parseInt(e.target.value);
        if (index <= imagesCount - 1) {
            setNavigateTo(index - 1)
        }
    };

    const handleGuideNextAndPre = async (direction: 'next' | 'pre') => {

        if (canvasOption.locked || confirm) {
            return;
        } else {
            if (JSON.stringify(canvasState.shapes) !== canvasState.originData && !confirm) {
                setConfirm(true)
                const result = await ChangePageConfirm();
                setConfirm(false)
                if (!result) {
                    return
                }
            };

            if (direction === 'next' && (currentIndex + 1) < imagesCount) {
                setNavigateTo(currentIndex + 1)
            };

            if (direction === 'pre' && !((currentIndex - 1) < 0)) {
                setNavigateTo(currentIndex - 1)
            }
        }
    };

    const handleSave = () => {
        return postSaveCanvasLabelAsync(canvasState.currentImage!.id, shapes);
    };
    const handleDeleteImageId = async () => {
        const check = await DeleteImageConfirm();
        if (check) {
            return deleteImageAsync(canvasState.currentImage!.id)
        }

    };

    useHotkeys('s', (): any => {
        if (modalOpen && saveRef && saveRef.current) {
            saveRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('a', (): any => {
        if (modalOpen && preRef && preRef.current) {
            preRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('d', (): any => {
        if (modalOpen && nextRef && nextRef.current) {
            nextRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('q', (): any => {
        if (modalOpen && lockRef && lockRef.current) {
            lockRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('e', (): any => {
        if (modalOpen && drawRef && drawRef.current && canvasOption.onDraw) {
            drawRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('r', (): any => {
        if (modalOpen && drawRef && drawRef.current) {
            drawRef.current.click();
        }
    }, [modalOpen]);
    useHotkeys('x', (): any => {
        if (modalOpen && removeRef && removeRef.current) {
            removeRef.current.click();
        }
    }, [modalOpen]);


    return (
        <div style={{
            position: 'relative',
            textAlign: 'center',
            width: 200, border: '0px solid', height: '100%', borderRadius: 10,
            boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        }}>
            <div style={{ textAlign: 'center', paddingTop: 15 }}>
                <h3>Current Index</h3>
                <input
                    type='number'
                    //onBlur={handleGuideToIndex}
                    value={currentIndex + 1}
                    min={1} max={imagesCount}
                    onChange={handleGuideToIndex}
                    style={{ width: '80%', textAlign: 'center', borderRadius: 5 }}
                />
            </div>
            <div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                    <Button
                        disabled={currentIndex === 0 || canvasOption.drawing}
                        ref={preRef}
                        icon={<CaretLeftOutlined />} onClick={() => handleGuideNextAndPre('pre')} />
                    <Button
                        ref={lockRef}
                        danger={canvasOption.locked}
                        icon={canvasOption.locked ? <LockOutlined /> : <UnlockOutlined />}
                        onClick={() => onSetCanvasOption('locked', !canvasOption.locked)} />
                    <Button
                        ref={nextRef} disabled={currentIndex === imagesCount - 1 || canvasOption.drawing}
                        icon={<CaretRightOutlined />} onClick={() => handleGuideNextAndPre('next')} />
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <br />
                <h3 >
                    Draw
                </h3>
                <div style={{ display: 'flex', gap: 8, justifyContent: 'center' }}>
                    {shapeButton
                        .filter(shape => allowTypes.includes(shape.shape))
                        .map(shape =>
                            <Button
                                key={shape.shape}
                                danger={canvasOption.onDraw && canvasOption.shapeType === shape.shape}
                                icon={shape.icon}
                                style={{ width: 32, height: 32, fontSize: 24 }}
                                onClick={() => onDrawShape(shape.shape)}
                            />
                        )}
                </div>
            </div>

            <div>
                <br />
                <h3>Classification</h3>
                <div style={{ padding: 10 }}>
                    <Select
                        size='small'
                        // labelInValue={true}
                        onSelect={(value) => onSetCanvasOption('defaultClassification', value)}
                        style={{ width: '80%' }}
                        value={canvasOption.defaultClassification}
                        options={[
                            ...classifications].map(item => {
                                return {
                                    label: item.description,
                                    value: item.id
                                }
                            })
                        }
                    />
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <br />
                <h3>Class Summary</h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', width: '100%' }}>
                    {classSummary.map(item =>
                        <ClassPicker
                            key={item.class}
                            classItem={item}
                            onClass={dataOption.classed === item.classlabel}
                            onClick={() => {
                                onSetDataOption({
                                    classed: item.classlabel,
                                    filterSource: 'All',
                                    isImport: undefined,
                                    filterLabeled: undefined
                                });
                                filterByClassification(item);
                            }
                            }
                        />
                    )}
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', flexDirection: "column", gap: 10, alignItems: 'center' }}>
                {contextHolder}
                <div style={{ width: '90%', display: canvasState.datasetType === "KEYPOINT" ? 'none' : 'block' }}>
                    <LabelSyntheticModal
                        imageUrl={`${offlineUrlExpress}/api/get-image?url=${canvasState.currentImage?.url}`}
                        datasetId={datasetId}
                        imageName={currentImage?.filename!}
                    />
                </div>
                <Button ref={saveRef} style={{ width: '90%' }} onClick={handleSave} disabled={error}>
                    Save [S]
                </Button>
                <Button ref={removeRef} danger style={{ width: '90%' }} onClick={handleDeleteImageId} disabled={error}>
                    Delete [X]
                </Button>
            </div>
        </div>
    );
};

export default Controller