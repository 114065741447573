import {
  BarsOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  FileMarkdownOutlined,
  ForkOutlined
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Menu } from "antd";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import CanvasDrawer from "../Drawer-best";
import OfflineControlPannel from "../Offline-Control-pannel/index";

const NestNavContainer = styled.div`
  width: 100%;
  position: sticky;
  z-index: 3;
  top: 4rem;
  display: flex;
  background: white;
`;
const MenuItem = styled(Menu.Item)`
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

export interface OfflineNestNavigationProps { }

const OfflineNestNavigation: React.FC<OfflineNestNavigationProps> = () => {
  const location = useLocation();
  const { url } = useRouteMatch();
  return (
    <NestNavContainer>
      <Menu mode="horizontal" selectedKeys={[location.pathname]} forceSubMenuRender={false} style={{ minWidth: '1000px' }}>
        <MenuItem
          key="/home/datasets"
          icon={<DatabaseOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <Link to={`${url}/datasets`}>Datasets</Link>
        </MenuItem>
        <MenuItem
          key="/home/models"
          icon={<FileMarkdownOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <Link to={`${url}/models`}>Trained Model</Link>
        </MenuItem>
        <MenuItem
          key="/home/valid"
          icon={<ClusterOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <Link to={`${url}/valid`}>Valided Result</Link>
        </MenuItem>
        <MenuItem
          key="/home/test"
          icon={<ForkOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <Link to={`${url}/test`}>Tested Result</Link>
        </MenuItem>
        <MenuItem
          key="/home/tasks"
          icon={<BarsOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <Link to={`${url}/tasks`}>Tasks</Link>
        </MenuItem>
      </Menu>
      <OfflineControlPannel />
      <CanvasDrawer />
    </NestNavContainer>
  );
};

export default OfflineNestNavigation;
