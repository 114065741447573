

import { message } from "antd";
import React, { ReactNode, useContext, useEffect, useReducer } from "react";
import { deleteImageByIdAsync, fetchClassSummaryApi, saveDetectLabels } from "./DrawerService";
import { CanvasStateType, Classification, ClassSummary, LabelDataType, Shape, ShapeImageJson } from './DrawerTypes';
import { fetchAllowType, fetchArrangeTypeRule, matchPointRectId } from "./DrawerUtils";

const historyMaxStep = 20;
const initialState: CanvasStateType = {
    modalOpen: false,
    datasetId: -1,
    datasetType: '',
    datasetName: '',
    imagesCount: 0,
    currentIndex: 0,
    currentImage: null,
    classifications: [],
    labelClassifications: [],
    classSummary: [],
    shapes: [],
    selectShape: null,
    history: [],
    historyStep: 0,
    success: false,
    fetching: false,
    error: false,
    allowTypes: ["rect", "circle", "polygon", "point", "line"],
    rules: [],
    refetch: false,
    originData: null
};

export type CanvasActionType = {
    type: 'open';
    datasetId: number;
    datasetType: LabelDataType;
    datasetName: string;
    classifications: Classification[];
    imagesCount: number;
    currentIndex: number;
} | {
    type: 'close'
} | {
    type: 'set_current_image',
    currentImage: null | ShapeImageJson,
    shapes: Shape[]
} | {
    type: 'navigate';
    index: number
} | {
    type: 'set_class_summary',
    classSummary: ClassSummary[]
} | {
    type: 'clear_canvas_element',
} | {
    type: 'update_current_dataset',
    shapes: Shape[]
} | {
    type: 'select_current_shape',
    shape: Shape | null
} | {
    type: 'delete_shape_by_id',
    shapeId: string
} | {
    type: 'set_visible_shape_box',
    shapes: Shape[]
} | {
    type: 'set_shape_classification_color',
    classSummary: ClassSummary[]
} | {
    type: 'set_history',
    record: string;
} | {
    type: 'undo'
} | {
    type: 'redo'
} | {
    type: 'set_shape_classification_id',
    classificationId: number | null;
    shapeId: string
} | {
    type: 'clear_history'
} |
{
    type: 'load_image',
    success: boolean
} | {
    type: 'refetch',
    refetch: boolean
} | {
    type: 'filter_by_class',
    totalCount: number;
}

function canvasReducer(state: CanvasStateType, action: CanvasActionType) {
    console.log('action', action.type)
    switch (action.type) {
        case 'open':
            return {
                ...state,
                modalOpen: true,
                datasetId: action.datasetId,
                datasetType: action.datasetType,
                datasetName: action.datasetName,
                classifications: [{ id: null, description: 'Empty Class', cid: 0 }, ...action.classifications],
                imagesCount: action.imagesCount,
                currentIndex: action.currentIndex,
                allowTypes: fetchAllowType(action.datasetType),
                rules: fetchArrangeTypeRule(action.datasetType),
            };
        case 'close':
            return { ...initialState }
        case 'set_current_image':
            return {
                ...state,
                currentImage: action.currentImage,
                shapes: action.shapes,
                originData: JSON.stringify(action.shapes)
            }
        case 'load_image':
            return {
                ...state,
                fetching: false,
                success: action.success,
                error: !action.success
            }
        case 'navigate':
            return {
                ...state,
                currentIndex: action.index,
                selectShape: null
            }
        case 'set_class_summary':
            return { ...state, classSummary: action.classSummary };
        case 'clear_canvas_element':
            return { ...state, shapes: [], selectShape: null };
        case 'update_current_dataset':
            return { ...state, shapes: action.shapes }
        case 'select_current_shape':
            return { ...state, selectShape: action.shape, }
        case 'delete_shape_by_id':
            return {
                ...state,
                shapes: state.shapes.filter(shape => shape.id !== action.shapeId),
                selectShape: null
            }
        case 'set_visible_shape_box':
            return {
                ...state,
                shapes: action.shapes
            };
        case 'set_shape_classification_color':
            return { ...state, classSummary: action.classSummary };
        case 'set_history': {
            if (state.history.length === 0) {
                return {
                    ...state,
                    history: [action.record],
                    historyStep: 0,
                };
            }
            const newHistory = [...state.history, action.record];
            const overMax = newHistory.length > historyMaxStep;
            if (overMax) {
                newHistory.shift();
                return {
                    ...state,
                    history: newHistory,

                }
            } else {
                return {
                    ...state,
                    history: newHistory,
                    historyStep: state.historyStep + 1
                }
            }
        }
        case 'undo': {
            if (state.historyStep === 0) {
                return { ...state };
            } else {
                const newStep = state.historyStep - 1;
                const newShapes = JSON.parse(state.history[newStep]) as Shape[];
                return {
                    ...state,
                    shapes: newShapes,
                    historyStep: newStep
                }
            }
        }
        case "redo": {
            const newStep = state.historyStep + 1;
            if (newStep >= state.history.length) {
                return { ...state };
            } else {
                const newShapes = JSON.parse(state.history[newStep]) as Shape[];
                return { ...state, shapes: newShapes, historyStep: newStep };
            }
        }
        case 'set_shape_classification_id': {
            const updated = [...state.shapes];
            const updatedIndex = updated.findIndex(shape => shape.id === action.shapeId);
            const newShape = { ...updated[updatedIndex], classificationId: action.classificationId }
            updated.splice(updatedIndex, 1, newShape);
            return {
                ...state,
                shapes: updated
            }
        };
        case 'clear_history': {
            return {
                ...state,
                history: []
            }
        }
        case 'refetch':
            return {
                ...state, refetch: action.refetch
            }
        case 'filter_by_class':
            return {
                ...state,
                imagesCount: action.totalCount,
                currentIndex: 0,
            }
        default:
            return { ...state };
    }
}

export interface CanvasContextType {
    canvasState: CanvasStateType,
    dispatchCanvas: React.Dispatch<CanvasActionType>;
    undo: () => void;
    redo: () => void;
    setShapeClassificationId: (shapeId: string, classificationId: number | null) => void;
    selectCurrentShape: (shape: Shape | null) => void;
    updateCurrentDataset: (shapes: Shape[]) => void;
    setRecordHistory: (shapes: Shape[]) => void;

    deleteShapeById: (shapeId: string) => void;
    setVisibleShapeBox: (shape: Shape) => void;
    clearCanvasElement: () => void;
    setClassSummary: (classSummaries: ClassSummary[]) => void;
    setCurrentImage: (currentImage: null | ShapeImageJson, shapes: Shape[]) => void;
    setCanvasDrawOpen: (
        datasetId: number,
        datasetType: LabelDataType,
        datasetName: string,
        classifications: Classification[],
        imagesCount: number,
        currentIndex: number
    ) => void;
    setClearHistory: () => void;
    onCloseCanvas: () => void;
    clearSelectShape: () => void;
    fetchClassSummaryAsync: (datasetId: number) => void;
    postSaveCanvasLabelAsync: (imageId: number, shapes: Shape[]) => void;
    deleteImageAsync: (imageId: number) => void;
    setNavigateTo: (index: number) => void;
    setRefetch: (refetch: boolean) => void;
    filterByClassification: (item: ClassSummary) => void;
};

const DrawerContext = React.createContext<CanvasContextType | undefined>(undefined);

export const useCanvas = (): CanvasContextType => {
    const context = useContext(DrawerContext);
    if (!context) {
        throw new Error('usePolygon must be used within a Provider');
    }
    return context;
};

export const DrawerProvider = ({ children }: { children: ReactNode }) => {
    const [canvasState, dispatchCanvas] = useReducer(canvasReducer, initialState);

    const setCanvasDrawOpen = (
        datasetId: number,
        datasetType: LabelDataType,
        datasetName: string,
        classifications: Classification[],
        imagesCount: number,
        currentIndex: number
    ) => {
        dispatchCanvas({
            type: 'open',
            datasetId: datasetId,
            datasetType: datasetType,
            datasetName: datasetName,
            classifications: classifications,
            imagesCount: imagesCount,
            currentIndex: currentIndex,
        })
    };
    const undo = () => {
        dispatchCanvas({
            type: 'undo',
        })
    };

    const redo = () => {
        dispatchCanvas({
            type: 'redo',
        })
    };

    const setShapeClassificationId = (shapeId: string, classificationId: number | null) => {
        dispatchCanvas({
            type: 'set_shape_classification_id',
            shapeId: shapeId,
            classificationId: classificationId
        })
    };

    const selectCurrentShape = (shape: Shape | null) => {
        dispatchCanvas({
            type: 'select_current_shape',
            shape: shape,
        })
    };

    const updateCurrentDataset = (shapes: Shape[]) => {
        if (canvasState.datasetType === 'KEYPOINT') {
            const pointShapes = shapes.map(shape => {
                if (shape.type === 'point') {
                    const { x, y } = shape.anchors[0]
                    const bindRect = matchPointRectId({ x, y }, shapes);
                    return {
                        ...shape,
                        binding: bindRect
                    }
                } else {
                    return shape
                }
            });
            dispatchCanvas({ type: 'update_current_dataset', shapes: pointShapes });
            setRecordHistory(pointShapes);
        } else {
            dispatchCanvas({ type: 'update_current_dataset', shapes: shapes });
            setRecordHistory(shapes);
        }


    };

    const setRecordHistory = (shapes: Shape[]) => {
        const record = JSON.stringify(shapes);
        dispatchCanvas({
            type: 'set_history',
            record: record
        })
    };



    const deleteShapeById = (shapeId: string) => {
        dispatchCanvas({
            type: 'delete_shape_by_id',
            shapeId: shapeId
        });
    };

    const setVisibleShapeBox = (shape: Shape) => {
        const preState = { ...canvasState };
        const updatedShapes = [...preState.shapes];
        const polygonIndex = updatedShapes.findIndex(box => box.id === shape.id);
        const updated = { ...shape, visible: !shape.visible };
        updatedShapes.splice(polygonIndex, 1, updated);
        dispatchCanvas({ type: 'set_visible_shape_box', shapes: [...updatedShapes] });
    };

    const clearCanvasElement = () => {
        dispatchCanvas({
            type: 'clear_canvas_element',
        })
    }
    const setClassSummary = (classSummaries: ClassSummary[]) => {
        dispatchCanvas({
            type: 'set_class_summary',
            classSummary: classSummaries
        })
    };
    const setCurrentImage = (currentImage: null | ShapeImageJson, shapes: Shape[]) => {
        dispatchCanvas({
            type: 'set_current_image',
            currentImage: currentImage,
            shapes: shapes
        })
    };

    const setClearHistory = () => {
        dispatchCanvas({
            type: 'clear_history'
        })
    };

    const onCloseCanvas = () => {
        dispatchCanvas({ type: 'close' });
    };

    const clearSelectShape = () => {
        dispatchCanvas({
            type: 'select_current_shape',
            shape: null,
        })
    };

    const fetchClassSummaryAsync = async (datasetId: number) => {
        try {
            const res = await fetchClassSummaryApi(datasetId);
            setClassSummary(res.data);
        } catch (err: any) {
            message.error(err.message)
        }
    };

    const postSaveCanvasLabelAsync = async (imageId: number, shapes: Shape[]) => {
        try {
            const classAmount = canvasState.classifications.filter(c => c.id).length;
            for (const ruleFunc of canvasState.rules) {
                const res = ruleFunc(shapes, classAmount);
                if (!res.pass) {
                    throw new Error(res.message);
                }
            };
            const res = await saveDetectLabels(canvasState.datasetType, imageId, shapes);
            if (res.success) {
                message.success(res.message)
                clearCanvasElement();
                return setRefetch(true)
                //return fetchClassSummaryAsync(canvasState.datasetId);
            } else {
                throw new Error(res.message)
            }
        } catch (err: any) {
            message.warn(err.message)
        }
    };

    const deleteImageAsync = async (imageId: number) => {
        try {
            const res = await deleteImageByIdAsync(imageId);
            if (res.success) {
                message.success(res.message)
                return setRefetch(true)
            } else {
                throw new Error(res.message)
            }
        } catch (err: any) {
            message.error(err.message)
        }
    }


    const setNavigateTo = (index: number) => {
        return dispatchCanvas({
            type: 'navigate',
            index: index
        });
    };

    const setRefetch = (refetch: boolean) => {
        dispatchCanvas({
            type: 'refetch',
            refetch: refetch
        })
    };

    const filterByClassification = (item: ClassSummary) => {
        const { imagecount } = item;
        dispatchCanvas({
            type: 'filter_by_class',
            totalCount: Number(imagecount)
        })
    };

    useEffect(() => {
        console.log('canvas state:', canvasState)
    }, [canvasState]);

    return (
        <DrawerContext.Provider value={{
            canvasState, setCanvasDrawOpen,
            dispatchCanvas, undo, redo, setRecordHistory,
            setShapeClassificationId, selectCurrentShape, updateCurrentDataset,
            deleteShapeById, setVisibleShapeBox, clearCanvasElement, setClassSummary,
            setCurrentImage, setClearHistory, onCloseCanvas, clearSelectShape,
            fetchClassSummaryAsync, postSaveCanvasLabelAsync, deleteImageAsync, setNavigateTo, setRefetch, filterByClassification
        }}>
            {children}
        </DrawerContext.Provider>
    )


};